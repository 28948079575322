<template>
  <v-rect
      ref="cinemaRect"
      :key="model.index"
      :config="{
          x: this.model.x,
          y: this.model.y,
          width: this.model.width,
          height: this.model.height,
          rotation: this.model.rotation,
          fillPatternImage: image,
          scaleX: this.model.scaleX,
          scaleY: this.model.scaleY,
          fillPatternScale: { x: this.scaleX, y: this.scaleY },
          fillPatternRepeat: 'no-repeat'
        }"
      @dragmove="onDragMove"
      @transform="onTransform"
      @click="selectRectangle()"
      :draggable="true"
  />
</template>

<script>
import {CinemaItemModel} from "@/models/cinema-item";

export default {
  props: {
    model: CinemaItemModel,
  },
  data() {
    return {
      image: null,
      stageWidth: 100,
      stageHeight: 100,
      scaleX: 1,
      scaleY: 1,
    }
  },
  watch: {
    model: {
      immediate: true,
      handler(newModel) {
        if (newModel && newModel.url) {
          this.loadImage(newModel.url);
        }
      }
    }
  },
  methods: {
    loadImage(url) {
      const img = new Image();
      img.onload = () => {
        this.image = img;
        this.model.width = img.width * 0.05;
        this.model.height = img.height * 0.05;
        this.scaleX = this.model.width / img.width;
        this.scaleY = this.model.height / img.height;
      };
      img.src = url;
    },
    onDragMove(e) {
      const shape = e.target;
      this.model.x = shape.x();
      this.model.y = shape.y();
    },
    onTransform(e) {
      const shape = this.$refs.cinemaRect.getNode();

      this.model.width = shape.width();
      this.model.height = shape.height();
      this.model.scaleX = shape.scaleX();
      this.model.scaleY = shape.scaleY();
      this.model.rotation = shape.rotation();
    },
    selectRectangle() {
      const rectNode = this.$refs.cinemaRect.getNode();
      this.$emit('cinema-item-selected', rectNode);
    },
  }
}
</script>

<style scoped>

</style>