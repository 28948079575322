<template>
  <div class="scene-view">
    <h1>Scene</h1>
    <!--menu component over-->
    <Menu @cinema-item-added="itemAdded"
          @stage-saved="stageSaved"
          @stage-loaded="stageLoaded" />

    <Hierarchy />    

    <!--scene-->
    <Stage ref="stage"
           v-bind:items="sceneItems"
           v-bind:layers="layers" />
    <!---->
  </div>  
  
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { CinemaItemModel } from "@/models/cinema-item";
import { LayerModel } from "@/models/layer";
import Hierarchy from "@/components/menu/Hierarchy.vue";
import Stage from "@/components/Stage.vue";
import Menu from "@/components/menu/Menu.vue";

let count = 0;

export default defineComponent({
  components: { Stage, Hierarchy, Menu },
  props: {
    $refs: {
      type: Object as any
    }
  },
  data(): { sceneItems: CinemaItemModel[], layers: LayerModel[] } {
    return {
      sceneItems: [],
      layers: [],
    }
  },
  methods: {

    itemAdded(itemUrl: string) {
      this.sceneItems.push(new CinemaItemModel(count, itemUrl));
      count++;
    },
    stageSaved: function () {
      this.$refs.stage.saveStage();
    },
    stageLoaded(jsonData: string) {
      const data = JSON.parse(jsonData);

      this.sceneItems = [];

      for(const obj of [...data]) {
        const item = CinemaItemModel.toItem(obj);
        if(item)
          this.sceneItems.push(item);
      }
    },

  }
})
</script>

<style scoped>
.scene-view {
  position: relative;
}

</style>