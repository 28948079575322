import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue';
import SceneView from "@/views/SceneView.vue";
import LoginView from "@/views/LoginView.vue";
import axios from "axios";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      // component: HomeView,
      redirect: to => "/landing/landing-mobile.html",
      meta: { requiresAuth: false }
    },
    {
      path: '/scene',
      name: 'scene',
      component: SceneView,
      meta: { requiresAuth: true }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView
    }, 
    {
      path: "/land-test",
      name: "land-test",
      redirect: to => "/landing/landing-mobile.html"
    }
  ]
});

router.beforeEach(async (to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {

      console.log("CLIENT auth token: ", localStorage.getItem('auth_token'));

      // Проверяем статус авторизации на сервере
      const response = await axios.get('/api/auth/status', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`, // Передаем токен
        },
      });

      console.log(response);

      // Если сервер отвечает, значит токен действителен
      if (response.data.status === 'authorized') {
        console.log('STATUS IS AUTHORIZED');
        next(); // Позволяем переход
      } else {
        console.log('STATUS ISN\'T AUTHORIZED');
        next({ path: '/login' }); // Перенаправляем на логин
      }
    } catch (error) {
      // В случае ошибки (например, токен устарел) перенаправляем на логин
      console.log('ERROR');
      next({ path: '/login' });
    }
  } else {
    next(); // Если маршрут не требует авторизации, продолжаем
  }


});

export default router
