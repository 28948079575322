<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-text-field
          v-model="formData.username"
          :rules="nameRules"
          label="Enter email"
          required
      ></v-text-field>

      <v-text-field
          v-model="formData.password"
          :rules="passwordRules"
          label="Enter password"
          required
      ></v-text-field>

      <v-btn @click="sendData" color="primary">Send</v-btn>

      <v-alert v-if="responseMessage" type="success" dismissible>
        {{ responseMessage }}
      </v-alert>
    </v-form>
  </v-container>
</template>

<script lang="ts">
  import { defineComponent } from 'vue';
  import apiClient from '@/api';
  import {serverUrl} from "@/utils/routes.utils";
  
  export default defineComponent({
    props: {
      $refs: {
        type: Object as any,
      }
    },
    data() {
      return {
        valid: false,
        formData: {
          username: '',
          password: '',
        },
        responseMessage: '',
        nameRules: [
          (v: string) => !!v || 'Name required',
          (v: string) => (v && v.length <= 100) || 'The name must be less than 100 characters',
        ],
        passwordRules: [
          (v: string) => !!v || 'The password cannot be empty!'
        ]
      };
    },
    methods: {
      async sendData() {
        //@ts-ignore
        if (this.$refs.form.validate()) {
          try {            
            const response = await apiClient.post(serverUrl + '/api/auth/login', {
              "username": this.formData.username,
              "password": this.formData.password
            });
            
            console.log('result is back!', response);

            this.responseMessage = response.data.message;

            const token = response.data.authToken;
            
            console.log('send data method:');
            console.log('data:');
            console.log(response.data);
            
            console.log('CLIENT got token:', token);
            
            localStorage.setItem('auth_token', token);
            localStorage.setItem('access_token', token);
            
          } catch (error) {
            console.error('Error when sending data:', error);
            this.responseMessage = 'Error when sending data';
          }
        }
      },
    },
  })
</script>

<style scoped>

</style>
