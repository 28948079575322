const imageFolder = "assets/images/";

export const getImageUrl = 
    (folder: string, file: string): string => imageFolder + folder + "/" + file;
export class CinemaTemplate {
    constructor(public readonly index: number, 
                public readonly name: string, 
                public readonly file: string) {
    }
}

export class CinemaCategory {
    constructor(public readonly index: number,
                public readonly name: string,
                public readonly folder: string,
                public readonly templates: CinemaTemplate[]) {
    }
}

export class CinemaList {
    constructor(public readonly categories: CinemaCategory[]) {
    }    
}

export const cinemaList: CinemaList = new CinemaList([
    new CinemaCategory(0, "Actors", "actors", [
        new CinemaTemplate(0, "Person A", "Person_A.png"),
        new CinemaTemplate(1, "Person B", "Person_B.png"),
    ]),
    new CinemaCategory(1, "Furniture", "furniture", [
        new CinemaTemplate(0, "Laptop", "Laptop.png"),
        new CinemaTemplate(1, "Chair", "Chair.png"),
        new CinemaTemplate(2, "Sofa", "Sofa.png"),
        new CinemaTemplate(3, "Table Round", "Table_2.png")
    ]),
    new CinemaCategory(2, "Lights", "lights", [
        new CinemaTemplate(0, "RT25 Tube Light", "RT25_Tube_Light.png"),
        new CinemaTemplate(1, "RC 60B", "RC_60B.png"),
        new CinemaTemplate(2, "RT25 Handheld Tube Light v1", "RT25_Handheld_Tube_Light_v1.png"),
        new CinemaTemplate(3, "RC 60B+RA-D30 mini Parabolic Softbox", "RC_60B+RA-D30_mini_Parabolic_Softbox.png"),
    ])
]);