<template>

  <div id="container"></div>

  <v-stage ref="stage"
           :config="stageConfig"
           @click="clickStage"
           class="stage">
    <v-layer ref="layer">

      <CinemaItem v-for="item of items"
                  ref="rects"
                  v-bind:model="item"
                  @cinema-item-selected="selectCinemaItem"
                  @cinema-item-updated="updateItem"
                  @cinema-item-image-added="updateLayer"/>

      <v-transformer
          ref="transformer"
          :config="transformerConfig" />
    </v-layer>
  </v-stage>
</template>

<script lang="ts">
import CinemaItem from "@/components/CinemaItem.vue";
import Konva from 'konva';
import {defineComponent} from "vue";

interface ComponentCustomProperties {
  $refs: {
    transformer: HTMLElement;
  };
}

export default defineComponent({
  components: {CinemaItem},
  props: ["items", "layers", "$refs"],
  data() {
    return {
      stageConfig: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
      transformerConfig: {
        rotateEnabled: true,
        enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
      },
    }
  },
  mounted() {

  },
  methods: {
    selectCinemaItem(rectNode: any) {
      const transformer = this.$refs.transformer.getNode();

      // Перемещаем выбранный прямоугольник на передний план
      rectNode.moveToTop();

      // Прикрепляем трансформер к выбранному прямоугольнику
      transformer.attachTo(rectNode);
      this.$refs.layer.getNode().batchDraw();
    },
    clickStage() {
      console.log('stage clicked');
      const transformer = this.$refs.transformer.getNode();
      // transformer.hide();
    },
    updateLayer() {
      this.$refs.layer.getNode().batchDraw();
    },

    updateItem(index: number, item: any) {
      //this.items[index] = item;
      console.log(this.items);
    },

    saveStage() {
      const json = JSON.stringify(this.items);
      const blob = new Blob([json], { type: 'application/json' });
      let filename = 'scene.json';
      // Создаем ссылку и связываем ее с Blob
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename; // Имя файла по умолчанию

      // Симулируем клик по ссылке для вызова диалога сохранения
      link.click();
      // Освобождаем объект URL после использования
      URL.revokeObjectURL(link.href);
    },
  }
})
</script>

<style scoped>
.stage {
  background-color: white;
}
</style>