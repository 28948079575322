export class CinemaItemModel {
    x: number = 50;
    y: number = 75;
    scaleX: number = 1;
    scaleY: number = 1;
    width: number = 100;
    height: number = 100;
    rotation: number = 0;
    
    constructor(public index: number, public url: string) {
    }
    
    
    static toItem(data: any): CinemaItemModel | null {
        try {
            const item = new CinemaItemModel(data.index, data.url);
            item.x = data.x;
            item.y = data.y;
            item.scaleX = data.scaleX;
            item.scaleY = data.scaleY;
            item.width = data.width;
            item.height = data.height;
            item.rotation = data.rotation;
            
            return item;
        }
        catch (e) {
            
        }
        
        return null; 
    }
}