<script setup lang="ts">

</script>

<template>
  <div class="hierarchy-view">

  </div>
</template>

<style scoped>
.hierarchy-view {
  position: absolute;
  top: 1rem;
  right: 1rem;

  border: 1px solid black;

}
</style>