<template>
  <div class="tool-menu">
    <button class="menu-btn" @click="addCinemaItem">+</button>
    <button class="menu-btn" @click="saveScene">save</button>
    <button class="menu-btn" @click="switchFileInput">load</button>

    <div v-if="showFileInput"
         class="file-input-container">
      <input type="file" @change="loadSceneFile($event)" />
    </div>


    <div v-if="showMenu"
         class="dropdown-menu dropdown-categories">
      <ul>
        <li v-for="({name, index}) in categories"
            :key="index"
            @pointerenter="chooseCategory(index, $event)"
            @pointerleave="chooseCategory(null)">
          <span>{{ name }}</span>

          <ul v-if="selectedCategory === index"
              class="dropdown-menu dropdown-templates"
              :style="{ top: `${submenuPositionY}px` }">
            <li v-for="({name, index: subIndex}) in categories[index].templates"
                :key="subIndex"
                @click="selectTemplate(index, subIndex)">
              <div style="display: flex;">
                <span>{{ name }}</span>
                <img style="height: 30px; width: 30px; margin-left: 10px" v-bind:src="getImage(index, subIndex)" />
              </div>
            </li>
          </ul>

        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">

import { defineComponent} from "vue";
import { cinemaList, getImageUrl } from "@/models/cinema-list";

export default defineComponent({
  data() {
    return {
      showMenu: false,
      showFileInput: false,
      categories: [...cinemaList.categories],
      selectedImage: null, // Выбранное изображение
      selectedCategory: null as number | null,
      submenuPositionY: 0,
    };
  },
  methods: {
    addCinemaItem() {
      this.showMenu = !this.showMenu;
    },

    saveScene() {
      this.$emit("stage-saved");
    },

    loadSceneFile($event: Event) {
      //@ts-ignore
      const file = $event.target!.files[0]; // Получаем файл из input
      if (file) {
        const reader = new FileReader(); // Создаем FileReader для чтения файла
        reader.onload = (e) => {
          const content = e.target!.result; // Данные файла (JSON в строке)
          //@ts-ignore
          const sceneData = JSON.parse(content); // Парсим строку в объект JSON

          // Пример использования загруженных данных для настройки конфигурации слоя
          // this.layerConfig = sceneData.layer;
          console.log(sceneData);
          this.$emit("stage-loaded", content);

        };
        reader.readAsText(file); // Читаем файл как текст
      }
    },

    switchFileInput() {
      this.showFileInput = !this.showFileInput;
    },

    selectTemplate(index: number, subIndex: number) {
      const category = this.categories[index];
      const template = category.templates[subIndex]
      this.showMenu = false;
      this.$emit("cinema-item-added", getImageUrl(category.folder, template.file));
    },
    chooseCategory(index: number | null, event?: MouseEvent) {
      this.selectedCategory = index;
      if (event) {
        const target = event.target as HTMLElement;
        this.submenuPositionY = target.offsetTop;
      }
    },
    getImage(index: number, subIndex: number) {
      const category = this.categories[index];
      const template = category.templates[subIndex]
      return getImageUrl(category.folder, template.file);
    }
  },
});
</script>

<style scoped>

.tool-menu {
  position: absolute;
  z-index: 9500;
  top: 1rem;
  left: 1rem;
}

.menu-btn {
  border-radius: 0.125rem;
  background: #4caf50;
  min-width: 2rem;
  height: 2rem;
  outline-style: auto;
  margin-right: 0.5rem;
}

/* Стили для выпадающего меню */
.dropdown-menu {
  position: absolute;
  color: white;
  background-color: #282828;
  border: 1px solid #ccc;
  padding: 0;
  z-index: 9600;
}

.dropdown-categories {
  min-width: 120px;
}

.dropdown-menu ul {
  margin: 0;
  padding: 0;
}

.dropdown-menu li {
  padding: 8px;
  cursor: pointer;
  list-style: none;
}

.dropdown-menu li:hover {
  background-color: darkorange;
}

.dropdown-templates {
  left: 100%;
  padding: 0;
  margin: 0;
  min-width: 140px;
}

.submenu li {
  padding: 10px;
}
</style>
